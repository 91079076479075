import { format } from 'date-fns'
import { truncate } from 'lodash'


export const addHighlight = (text, searchText, matchCase) => {
  if (text && searchText) {
    searchText = searchText.trim()
    let match = searchText.match(/^"(.*?)"$/)
    if (matchCase || (match && match.length >= 2)) {
      let s = matchCase ? searchText : match[1]
      text = text.replace(new RegExp('(' + s + ')', 'g'), '<span class="highlight">$1</span>')
    }
    else {
      let ss = searchText.split(/\s+/)
      ss.forEach(s => { if (s) text = text.replace(new RegExp('(' + s + ')', 'gi'), '<span class="highlight">$1</span>') })
    }
  }
  return text || ''
}

export const toNum = (num, digits = 2, trimZero = false) => {
  let str = toNumber(num, digits, digits)
  if (trimZero) str = str.replace(/\.?0+$/, '').replace(/^-?0$/, '')
  return str;
}

export const toNumber = (value, minDigits, maxDigits) => {
  if (value === undefined || value === '' || value === null) return ''
  let options = { style: 'decimal' }
  if (minDigits !== undefined) options.minimumFractionDigits = minDigits
  if (maxDigits !== undefined) options.maximumFractionDigits = maxDigits
  var formatter = new Intl.NumberFormat('en-US', options)
  return formatter.format(+value)
}

export const toPercent = (value, minDigits, maxDigits) => {
  if (value === undefined || value === '' || value === null || Math.abs(value) < 0.00001) return ''
  let options = { style: 'percent' }
  if (minDigits !== undefined) options.minimumFractionDigits = minDigits
  if (maxDigits !== undefined) options.maximumFractionDigits = maxDigits
  var formatter = new Intl.NumberFormat('en-US', options)
  return formatter.format(+value)
}

export const toCurrency = (value, minDigits, maxDigits) => {
  if (value === undefined || value === '' || value === null || Math.abs(value) < 0.00001) return ''
  let options = { currency: 'USD', style: 'currency' }
  if (minDigits !== undefined) options.minimumFractionDigits = minDigits
  if (maxDigits !== undefined) options.maximumFractionDigits = maxDigits
  var formatter = new Intl.NumberFormat('en-US', options)
  return formatter.format(+value)
}

export const toDate = (time) => {
  try {
    if (time instanceof Date) return time
    return new Date(time)
  } catch (err) {
    console.error(`toDate(${time}) error:`, err)
    return new Date(0)
  }
}

export const toISODate = (date) => {
  if (typeof (date) === 'string') {
    if (/^\d+$/.test(date)) date = new Date(+date)
    else date = new Date(date)
  }
  // return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
  return date
}

export const toMilliseconds = (time) => {
  return toDate(time).getTime()
}

export const toSeconds = (time) => {
  return Math.floor(toMilliseconds(time) / 1000)
}

export const getTimeDiffInSec = (fromTime, toTime, pausedInSec = 0) => {
  return toSeconds(toTime) - toSeconds(fromTime) - pausedInSec
}

export const getTimeDiffInText = (fromTime, toTime, pausedInSec = 0) => {
  return secondsToText(getTimeDiffInSec(fromTime, toTime, pausedInSec))
}

export const getTimeLeftInText = (startTime, spanInSeconds, pausedInSec = 0) => {
  return getTimeDiffInText(new Date(), toMilliseconds(startTime) + spanInSeconds * 1000, pausedInSec)
}

export const millisecondsToText = (ms, showMs = true, msDigits = 3) => {
  let sec = Math.floor(ms / 1000)
  ms = ms % 1000
  let min = Math.floor(sec / 60)
  sec = sec % 60
  let hr = Math.floor(min / 60)
  min = min % 60
  let txt
  if (hr > 0) txt = `${hr}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`
  else txt = `${min}:${sec.toString().padStart(2, '0')}`
  if (showMs) txt += `.${ms.toString().slice(0, msDigits)}`
  return txt
}

export const secondsToText = (sec) => {
  return millisecondsToText(sec * 1000, false)
}

//---------------------------------------------------------

export const getMinMax = (arr, field) => {
  const mm = arr.reduce((t, x) => {
    t.min = x[field] < t.min ? x[field] : t.min ?? x[field]
    t.max = x[field] > t.max ? x[field] : t.max ?? x[field]
    t.sum = t.sum + x[field]
    return t
  }, { min: undefined, max: undefined, sum: 0 })
  mm.avg = mm.sum / arr.length
  return mm
}

//---------------------------------------------------------
// pickAs(item, 'name,msg|message')
export const pickAs = (obj, fields, defValue = '') => {
  let result = {}
  if (typeof (fields) === 'string') fields = fields.split(',')
  fields.forEach(x => {
    let [x1, x2, x3] = x.split(/>|\|/) // originalName|newName|falsyDefault
    if (x3) result[x2 || x1] = obj[x1] || x3
    else result[x2 || x1] = obj[x1] || defValue
  })
  return result
}

export const setFields = (obj, fromObj, fields = null) => {
  if (!fields) fields = Object.keys(fromObj)
  else if (typeof (fields) === 'string') fields = fields.split(',')
  fields.forEach(x => {
    obj[x] = fromObj[x]
  })
}

export const getUnique = (array) => {
  return [...new Set(array)]
  // return array.filter((x, i, arr) => arr.indexOf(x) === i)
}

export const doSearch = (obj, searchText, fields) => {
  if (!searchText) return true
  searchText = searchText.toLowerCase()
  if (typeof (fields) === 'string') fields = fields.split(',')
  return !!fields.find(x => {
    if (typeof (obj[x]) === 'string') return obj[x]?.toLowerCase().includes(searchText)
    else if (Array.isArray(obj[x])) return obj[x].find(y => y.toLowerCase().includes(searchText))
    else return false
  })
}

export const doSort = (arr, sortBy, sortDir) => {
  if (!arr) return null
  let sign = sortDir === 'asc' ? 1 : -1
  return arr.sort((a, b) => a[sortBy] > b[sortBy] ? sign : a[sortBy] < b[sortBy] ? -sign : 0)
}

export const capitalize = (text) => {
  return text && text[0].toUpperCase() + text.slice(1)
}

export const trimTo = (txt, len) => {
  if (txt?.length > len) return txt.substring(0, len) + '...'
  return txt
}

export const fmtTo = (text, type, digits, trimTo, dateFmt = 'yyyy-MM-dd', showZero) => {
  if (text === undefined) return ''
  if (!showZero && !text) return ''
  switch (type) {
    case 'number': return toNum(text, digits, true)
    case "currency": return toNum(text, digits)
    case "percent": return toPercent(text, digits)
    case "date": return format(new Date(text), dateFmt);
    default:
      if (trimTo) return truncate(text, { length: trimTo })
      else return text
  }
}

export const copyTable = (table, columns) => {
  let str = columns.filter(x => x.visible).map(x => x.name).join('\t')
  table.forEach((x, i) => {
    str += '\n'
    columns.filter(x => x.visible).forEach((y, j) => {
      if (y.id === 'index') str += i + 1
      else str += fmtTo(x[y.id], y.type, y.digits, 0, y.fmt)
      str += '\t'
    })
  })
  return navigator.clipboard.writeText(str)
}

export const convertUnicode = (input) => {
  return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a, b) => String.fromCharCode(parseInt(b, 16)));
}

export const isLastWorkDay = (date) => {
  let now = new Date(new Date().toISOString().substring(0, 10)+'T00:00')
  let dt = new Date(date + 'T00:00')
  // console.log(now, dt, now.getDay(), dt.getDay(), now.getTime() - dt.getTime(), 3 * 24 * 3600 * 1000, now.getDay() === 0 && dt.getDay() === 5, now.getDay() - dt.getDay() <= 1)
  if (now.getTime() - dt.getTime() < 3 * 24 * 3600 * 1000) return (now.getDay() === 0 && dt.getDay() === 5) || (now.getDay() - dt.getDay() <= 1)
  return false
}

export const getPositionColor = (position) => {
  if (position <= 0.001) return 'magenta'
  else if (position <= 0.01) return '#fa8072'
  else if (position <= 0.05) return '#ffa07a'
  else if (position <= 0.1) return '#dda0dd'
  // else if (position <= 0.2) return '#ffc0cb'
  else if (position >= 0.999) return 'lime'
  else if (position >= 0.99) return '#00ff7f'
  else if (position >= 0.95) return '#adff2f'
  else if (position >= 0.9) return '#98fb98'
  // else if (position >= 0.8) return '#c9ffe5'
}

export const getPriceColor = (position) => {
  if (position <= 0.001) return 'magenta'
  // else if (position <= 0.01) return '#fa8072'
  // else if (position <= 0.05) return '#ffa07a'
  // else if (position <= 0.1) return '#dda0dd'
  // // else if (position <= 0.2) return '#ffc0cb'
  else if (position >= 0.999) return 'lime'
  // else if (position >= 0.99) return '#00ff7f'
  // else if (position >= 0.95) return '#adff2f'
  // else if (position >= 0.9) return '#98fb98'
  // else if (position >= 0.8) return '#c9ffe5'
}

export const getVolumeColor = (vol, avgVol) => {
  if (vol < 1000000 || avgVol < 1000000) return ''
  const pct = 100 * (vol - avgVol) / avgVol
  if (pct > 500) return '#ff4500'   // red orange
  else if (pct > 200) return '#f08080'  // light coral
  else if (pct > 100) return '#ffff00'  // yellow
  return ''
}
