import { toNumber } from '../../libs/utilLib'

export const QuoteRange = ({low=0, high=0, close=0, open=0, kind}) => {
    let d = high - low
    if (!d) return null
    let op = d && open ? (open - low) * 100 / d + '%' : null
    let cl = d && close ? (close - low) * 100 / d + '%' : null
    let w = Math.abs(close - open) * 100 / d
    let wd = d && w <= 100 ? w + '%' : null
    let st = d ? (close > open ? op : cl) : null 
    let color = close >= open ? 'blue' : 'red'
    return (
      <div className="p-relative" style={{fontSize: '75%'}}>
        <div style={{backgroundColor: 'lightgrey', height: '2px', margin: '3px 0', minWidth: 80}}>&nbsp;</div>
        {kind === 'year' ? <>
          {cl && <div className="p-absolute" style={{left: cl, top: -2, backgroundColor: color, height: '6px', width: '6px', borderRadius: '50%'}}>&nbsp;</div>}
        </> : <>
          {wd && st && <div className="p-absolute" style={{top: 0, left: st, width: wd, backgroundColor: color, height: '2px'}}>&nbsp;</div>}
          {cl && <div className="p-absolute" style={{left: cl, top: -2, backgroundColor: color, height: '6px', width: '6px', borderRadius: '50%'}}>&nbsp;</div>}
        </>}
        <div>
          <span className="float-end">{toNumber(high)}</span>
          <span className="float-start">{toNumber(low)}</span>
        </div>
      </div>
    )
}
