import { useState, useContext } from 'react'
import { Table, Row, Col } from 'react-bootstrap'
import { FaEllipsisH, FaEllipsisV, FaEdit, FaPlus, FaCopy, FaCaretDown } from 'react-icons/fa'
import { DateTime, Checkbox, ThSort, DivHtml, MyPopover, LinkButton, NoData, ALink, QuoteRange, AutoRefresh, Pin, Ticker } from '../widgets'
import { toCurrency, toPercent, toNum, toNumber, copyTable, getPositionColor, getPriceColor, getVolumeColor } from '../../libs/utilLib'
import { holdingsView as setting } from '../../libs/consts'
import { orderBy, cloneDeep } from 'lodash'
import { apiPost } from '../../libs/apiLib'
import { toast } from 'react-toastify'
import { Transactions } from '../transaction'
import { HoldingsChart } from '.'
import { Orders } from '../order'
import { GlobalContext } from '../helpers'
import { AddSymbolToWatchlist } from '../watchlist'
import { processAccount } from '../../libs/dataLib'
import { differenceInDays } from 'date-fns'
// import { Link } from 'react-router-dom'

export const Holdings = ({ account, onRefresh, embedded, showHoldings, showOrders, hideMF = false, pQuote = false }) => {
    const prefix = 'Holdings'
    const { gState } = useContext(GlobalContext)
    const [state, setState] = useState({
        visible: JSON.parse(localStorage.getItem(`${prefix}_visible_${account._id}`) || true),
        // height: JSON.parse(localStorage.getItem(`${prefix}_height_${account._id}`) || 800),
        // pinResize: JSON.parse(localStorage.getItem(`${prefix}_pinResize`) || true),
        highlightOnly: false,
        columns: JSON.parse(localStorage.getItem(`${prefix}_columns`)) || setting.columns,
        hideMF: JSON.parse(localStorage.getItem(`${prefix}_hideMF`)),
        pQuote: JSON.parse(localStorage.getItem(`${prefix}_pQuote`)),
        pinTrans: JSON.parse(localStorage.getItem(`${prefix}_pinTrans`) || true),
        showTransactions: JSON.parse(localStorage.getItem(`${prefix}_showTransactions`)),
        showOrders: JSON.parse(localStorage.getItem(`${prefix}_showOrders`)),
        showCharts: JSON.parse(localStorage.getItem(`${prefix}_showCharts`)),
        sortKind: JSON.parse(localStorage.getItem(`${prefix}_sortKind`) || true),
        sortBy: JSON.parse(localStorage.getItem(`${prefix}_sortBy`)) || 'symbol',
        sortDir: JSON.parse(localStorage.getItem(`${prefix}_sortDir`)) || 'asc',
        hideEmpty: JSON.parse(localStorage.getItem(`${prefix}_hideEmpty`) || true),
        showFavor: JSON.parse(localStorage.getItem(`${prefix}_showFavor`)),
        searchText: '',
        filters: null,
        filterText: null,
    })

    const refreshQuotes = async (range = '1d', source = 'yfinance2') => {
        setState({ ...state, working: true })
        let symbols = [...new Set([...account.holdings.map(x => x.symbol + '|' + (x.company?.yahooSymbol || '')), ...account.orders?.map(x => x.symbol + '|' + (x.company?.yahooSymbol || ''))])]
        let { updatedCount } = await apiPost('fetch/quotes', { symbols: symbols.join(','), range, source })
        if (updatedCount) {
            toast(`${updatedCount} out of ${symbols.length} quotes refreshed.`)
            onRefresh(account._id)
        } else {
            toast(`No quotes refreshed.`)
        }
        setState({ ...state, working: false })
    }

    const refreshYearly = async () => {
        await refreshQuotes('1y', 'ysparkline')
    }

    // const onResize = (size) => {
    //     let { height } = state
    //     height = Math.max(height + size, 0)
    //     localStorage.setItem(`${prefix}_height_${account._id}`, JSON.stringify(height))
    //     setState({ ...state, height })
    // }

    const onVisible = (name='visible') => {
        localStorage.setItem(`${prefix}_${name}_${account._id}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    const onToggle = (name) => {
        localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
        setState({ ...state, [name]: !state[name] })
    }

    const onStop = (e) => {
        e.stopPropagation()
    }

    // const onSearch = (searchText) => {
    //     setState({ ...state, searchText, skip: 0 })
    // }

    const onShow = (i) => {
        let { columns } = state
        columns[i].visible = !columns[i].visible
        localStorage.setItem(`${prefix}_columns`, JSON.stringify(columns))
        setState({ ...state, columns })
    }

    const onReset = () => {
        localStorage.removeItem(`${prefix}_columns`)
        setState({ ...state, columns: setting.columns })
    }

    const onSort = (sortBy) => {
        const sortDir = sortBy === state.sortBy ? (state.sortDir === 'desc' ? 'asc' : 'desc') : 'asc'
        localStorage.setItem(`${prefix}_sortBy`, JSON.stringify(sortBy))
        localStorage.setItem(`${prefix}_sortDir`, JSON.stringify(sortDir))
        setState({ ...state, sortBy, sortDir })
    }

    const onCopy = () => {
        let result = copyTable(items, columns)
        console.log(result)
        toast(`Table copied into clipboard.`)
    }

    const showTrans = (symbol) => {
        setState({ ...state, showTransactions: true, symbol })
    }

    const canShow = (id) => {
        let isSpecial = 'dayRange,yearRange'.includes(id)
        if (!isSpecial && state.hideEmpty && !items.find(x => x[id])) return false
        return state.columns.find(x => x.id === id)?.visible
    }

    const getTickerRemarks = (company) => {
        const { tickerRemarks = [] } = {} //gState.loginUser
        let tr = tickerRemarks.find(x => x.symbol === company.symbol)
        return tr ? tr.remark : null
    }

    // const onYahooCharts = () => {
    //     account.holdings.map(x => x.yahooSymbol || x.symbol).forEach(x => window.open(`https://finance.yahoo.com/chart/${x}`))
    // }

    const getBgColor = (pct) => {
        if (Math.abs(pct) > 0.15) return '#80ff00'
        else if (Math.abs(pct) > 0.10) return '#00ffff'
        else if (Math.abs(pct) > 0.05) return '#ffff00'
        return ''
    }

    const getBgColor2 = (pct) => {
        if (Math.abs(pct) > 0.8) return '#80ff00'
        else if (Math.abs(pct) > 0.4) return '#00ffff'
        else if (Math.abs(pct) > 0.2) return '#ffff00'
        return ''
    }

    const getBgColor3 = (pct) => {
        if (pct > 0.15) return '#80ff00'
        else if (pct > 0.10) return '#00ffff'
        else if (pct > 0.05) return '#ffff00'
        else if (pct < -0.15) return '#ff4500'
        else if (pct < -0.1) return '#f08080'
        else if (pct < -0.05) return '#ffa07a'
        return ''
    }
    
    const getBgColor4 = (pct) => {
        if (pct > 1) return '#f08080'
        else if (pct > 0.5) return '#ffff00'
        return ''
    }
    
    const getBgColor5 = (pct) => {
        if (pct >= 1) return '#ff4500'
        else if (pct >= 0.75) return '#f08080'
        else if (pct >= 0.5) return '#ffff00'
        return ''
    }
    
    const getDateColor = (dt) => {
        if (!dt) return
        if (typeof (dt) == 'string') dt = new Date(dt)
        let days = differenceInDays(dt, new Date())
        if (days < 0) return
        if (days <= 7) return '#ffff00'
        else if (days <= 14) return '#00ffff'
        else if (days <= 30) return '#80ff00'
        return ''
    }

    const getSymbolUrl = (symbol) => `https://finance.yahoo.com/chart/${symbol}`

    const dropdown = () => <>
        <hr className="my-1" />
        <LinkButton text="Refresh yearly spark quotes" onClick={e => refreshYearly()} className="ps-0" />
        {!embedded && <>
            <hr className="my-2" />
            <div><Checkbox label="Hide MF" checked={state.hideMF} onCheck={e => onToggle('hideMF')} /></div>
            <div><Checkbox label="Use p-quote" checked={state.pQuote} onCheck={e => onToggle('pQuote')} /></div>
        </>}
        {/* <hr className="my-2" /> */}
        {/* <Pin pinned={state.pinResize} onClick={e => onToggle('pinResize')} className="my-2"> */}
        {/* <div>
            Window height:
            <Link onClick={e => onResize(200)} className='ms-3'><FaChevronDown/></Link>
            <Link onClick={e => onResize(-200)} className='ms-3'><FaChevronUp/></Link>
        </div> */}
        {/* </Pin> */}
        <hr className="my-2" />
        <div><Checkbox label="Distribution charts" checked={state.showCharts} onCheck={e => onToggle('showCharts')} /></div>
        <div><Checkbox label="Transactions" checked={state.showTransactions} onCheck={e => showTrans(null)} /></div>
        <div><Checkbox label="Orders" checked={state.showOrders} onCheck={e => onToggle('showOrders')} /></div>
        <hr className="my-1" />
        <div><LinkButton to={`/account/${account._id}/perf`} target="_blank" text="Account performance" className="ps-0" /></div>
        <div><LinkButton to={`/user/${gState.loginUser?._id}/tax?aid=${account._id}`} target="_blank" text="Account tax report" className="ps-0" /></div>
        {/* <div><LinkButton to={`/user/${gState.loginUser?._id}:${account._id}/tax`} target="_blank" text="Account tax report" className="ps-0" /></div> */}
        <hr className="my-1" />
        <div><LinkButton to={`/account/${account._id}/transaction/import`} text="Import transactions" className="ps-0" /></div>
        <hr className="my-1" />
        <LinkButton text={state.visible ? 'Hide holdings' : 'Show holdings'} onClick={e=>onVisible()} className="ps-0" />
    </>

    const { columns, sortBy, sortDir } = state
    let account1 = processAccount(cloneDeep(account), embedded ? hideMF : state.hideMF, embedded ? pQuote : state.pQuote)
    let items = account1.holdings
    // if (searchText && !highlightOnly) items = items.filter(x => doSearch(x, searchText, setting.searchFields))
    if (sortBy && sortDir) items = state.sortKind ? orderBy(items, ['kind', sortBy], ['asc', sortDir]) : orderBy(items, [sortBy], [sortDir])
    const hiddenMF = account.holdings?.length - account1.holdings?.length
    // console.log(items)
    return (
        <div className='mt-2'>
            {(!embedded || showHoldings) && <div>
                <div className={embedded ? '' : gState.showMarketCharts ? '' : 'move-header-to-top'}>
                    <div className={`float-end ${embedded ? '' : 'mt-2'}`}>
                        {/* {canShow('pPrice') && <Checkbox type="switch" label="Use after market quote" checked={state.useAfterMarket} onCheck={e => onToggle('useAfterMarket')} />} */}
                        {/* {pinCharts && <LinkButton icon={<FaChartBar />} variant={variant} onClick={e => onToggle('showCharts')} className="ms-2" />} */}
                        <AutoRefresh key="holdings" id="holdings" working={state.working} variant="outline-primary" doRefresh={e => refreshQuotes()} dropdown={dropdown()} className="ms-2" />
                    </div>
                    <div className={`mt-2 d-inline-block ${embedded ? 'h6' : 'h5'}`}>Holdings [{account.name}]</div>
                    {items?.length > 0 && <MyPopover button={<FaCaretDown className="p-cursor link"/>} style={{ minWidth: 220 }}>
                        <div>
                            <Checkbox label="Sorted first" checked={state.sortKind} onCheck={e => onToggle('sortKind')} className="float-end"/>
                            <b>Kind:</b>
                        </div>
                    </MyPopover>}
                    {/* <div className="ms-2 d-inline-block mt-2 f-sm" style={{verticalAlign: 'top'}}>
                        <Link onClick={e => onVisible()}>{state.visible ? <>Hide</> : <>Show</>}</Link>
                        {state.visible && state.pinResize && <>
                            <Link onClick={e => onResize(200)} className='ms-3'><FaChevronDown/></Link>
                            <Link onClick={e => onResize(-200)} className='ms-3'><FaChevronUp/></Link>
                        </>}
                    </div> */}
                </div>

                {state.visible && <>{!items?.length ? <NoData /> : <div>
                    {/* style={{ maxHeight: state.height, maxWdith: "100%", overflow: 'auto' }} */}
                    <div className="nowrap clear-both"> 
                        <Table size="sm" hover className="nowrap f-sm mb-0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    {columns.filter(x => canShow(x.id)).map((x, i) => <ThSort key={i} id={x.id} text={x.name} sortBy={sortBy} sortDir={sortDir} onSort={onSort} className={x.className} />)}
                                    <th className="text-end">
                                        <MyPopover button={<FaEllipsisH className="grey mt-n1" />} className="ms-2 mt-n1">
                                            <div><LinkButton icon={<FaCopy />} text="Copy table" onClick={onCopy} className="ps-0" /></div>
                                            <hr className="my-1" />
                                            <div><Checkbox label="Hide empty columns" checked={state.hideEmpty} onCheck={e => onToggle('hideEmpty')} /></div>
                                            <div><Checkbox label="Show favor icon" checked={state.showFavor} onCheck={e => onToggle('showFavor')} /></div>
                                            <hr className="my-1" />
                                            <div className="float-end"><LinkButton text="Reset to default" onClick={onReset} className="p-0" /></div>
                                            <div className="bold">Show columns</div>
                                            <Row className="clear-both mt-2" onClick={onStop}>
                                                {columns?.map((x, i) => <Col key={i} xs={6}>
                                                    <Checkbox label={x.name} checked={x.visible} onCheck={e => onShow(i)} />
                                                </Col>)}
                                            </Row>
                                        </MyPopover>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((x, i) => <tr key={i} className={state.sortKind && i < items.length-1 && x.kind !== items[i+1].kind ? 'separator-blueviolet' : ''}>
                                    <td><LinkButton text={i + 1} to={`/chart/${x.symbol}`} target="_blank" className="p-0" /></td>
                                    {canShow('symbol') && <td title={x.name}>
                                        <Ticker symbol={x.symbol} yahooSymbol={x.yahooSymbol} showFavor={state.showFavor}/>
                                        {/* <div className="float-end">
                                            <MyPopover button={gState.isFavor(x.symbol, 'watch') ? <FaHeart className="blueviolet" title="Watching" /> : gState.isFavor(x.symbol, 'buy') ? <FaThumbsUp className="blueviolet" title="Watch for buy" /> : gState.isFavor(x.symbol, 'sell') ? <FaThumbsDown className="blueviolet" title="Watch for sell" /> : <FaRegHeart title="Favorite?" />}>
                                                <div><LinkButton icon={gState.isFavor(x.symbol, 'watch') ? <FaHeart className="blueviolet" /> : <FaRegHeart />} text="Watching" onClick={e => gState.onFavor(x.symbol, 'watch')} /></div>
                                                <div><LinkButton icon={gState.isFavor(x.symbol, 'buy') ? <FaThumbsUp className="blueviolet" /> : <FaRegThumbsUp />} text="Watch for buy" onClick={e => gState.onFavor(x.symbol, 'buy')} /></div>
                                                <div><LinkButton icon={gState.isFavor(x.symbol, 'sell') ? <FaThumbsDown className="blueviolet" /> : <FaRegThumbsDown />} text="Watch for sell" onClick={e => gState.onFavor(x.symbol, 'sell')} /></div>
                                            </MyPopover>
                                        </div>
                                        <ALink href={`https://finance.yahoo.com/chart/${x.yahooSymbol || x.symbol}`} text={x.symbol} /> */}
                                    </td>}
                                    {canShow('name') && <td title={x.name} style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {x.tags && <div className="float-end f-xs">{x.tags?.map((y, j) => <span key={j} className="px-1 grey me-1" style={{ border: '1px solid #ccc', borderRadius: 5, backgroundColor: y.startsWith('DJ') ? 'azure' : y.startsWith('NQ') ? 'seashell' : y.startsWith('DK') ? 'orange' : y.startsWith('DA') ? 'pink' : y.startsWith('DV') ? 'cyan' : '' }}>{y}</span>)}</div>}
                                    <ALink href={`https://finance.yahoo.com/quote/${x.yahooSymbol || x.symbol}`} text={x.name} />
                                    </td>}
                                    {canShow('price') && <td className="text-end bold" title={`Pos: ${toPercent(x.position, 2) || '0%'} Up: ${toPercent(x.upPercent, 2) || '0%'} Down: ${toPercent(x.downPercent, 2)}`}><span style={{backgroundColor: getPriceColor(x.position)}}>${toNum(x.price * (x.factor || 1), 4, true)}</span></td>}
                                    {/* {canShow('price') && <td className="text-end bold" title={toPercent(x.position, 2)}><span style={{backgroundColor: getPositionColor(x.position)}}>${toNum(x.price * (x.factor || 1), 4, true)}</span></td>} */}
                                    {canShow('change') && <td className="text-end" style={{ color: x.change < 0 ? 'red' : 'blue' }}>{toCurrency(x.change)}</td>}
                                    {canShow('changePercent') && <td><div className={`${x.change > 0 ? 'blue' : 'red'} text-end`} style={{ backgroundColor: getBgColor(x.changePercent / 100) }}>{!!x.changePercent && toPercent(x.changePercent / 100, 2)}</div></td>}
                                    {canShow('lastTraded') && <td className="text-center"><div style={{backgroundColor: differenceInDays(x.lastTraded, new Date()) < -5 ? 'red':''}}><DateTime date={x.lastTraded} kind="absolute" compact /></div></td>}
                                    {/* {canShow('lastTraded') && <td className="text-center"><DateTime date={x.lastTraded} kind="absolute" compact /></td>} */}
                                    {canShow('dayRange') && <td className="text-center"><QuoteRange low={x.dayLow} high={x.dayHigh} close={x.price} open={x.open} /></td>}
                                    {canShow('volume') && <td className="text-end" title={`3M avg: ${toNumber((x.avgVolume||0)/1000, 1, 1)}, 10D avg: ${toNumber((x.avgVolume10D||0)/1000, 1, 1)}`}><div style={{backgroundColor: getVolumeColor(x.volume, x.avgVolume)}}>{x.volume ? toNumber(x.volume / 1000, 1, 1) : ''}</div></td>}
                                    {canShow('avgVolume') && <td className="text-end">{x.avgVolume ? toNumber(x.avgVolume / 1000, 1, 1) : ''}</td>}
                                    {canShow('yearRange') && <td className="text-center"><QuoteRange low={Math.min(x.yearLow, x.dayLow||x.yearLow)} high={Math.max(x.yearHigh, x.dayHigh)} close={x.price} open={x.open} kind="year" /></td>}
                                    {canShow('position') && <td className="text-end"><span style={{ backgroundColor: getPositionColor(x.position) }}>{toPercent(x.position, 2)}</span></td>}
                                    {canShow('upPercent') && <td className="text-end">{toPercent(x.upPercent, 2)}</td>}
                                    {canShow('downPercent') && <td className="text-end">{toPercent(x.downPercent, 2)}</td>}
                                    {canShow('tickerRemarks') && <td>{getTickerRemarks(x)}</td>}
                                    {canShow('quantity') && <td className="text-end">{toNum(x.quantity, 5, true)}</td>}
                                    {canShow('avgPrice') && <td className="text-end">${toNum(x.avgPrice, 4, true)}</td>}
                                    {canShow('paid') && <td className="text-end">{toCurrency(x.paid)}</td>}
                                    {canShow('marketValue') && <td className="text-end">{toCurrency(x.marketValue)}</td>}
                                    {canShow('dayChange') && <td className="text-end bold" style={{ color: x.dayChange < 0 ? 'red' : 'blue' }}>{!!x.dayChange && toCurrency(x.dayChange)}</td>}
                                    {canShow('winAmount') && <td className="text-end" style={{ color: x.winAmount < 0 ? 'red' : 'blue' }}>{toCurrency(x.winAmount)}</td>}
                                    {canShow('winPercent') && <td className="text-end" style={{ color: x.winAmount < 0 ? 'red' : 'blue' }}>{toPercent(x.winPercent, 2)}</td>}
                                    {canShow('percentage') && <td className="text-end">{toPercent(x.percentage, 1)}</td>}
                                    {canShow('mktCap') && <td className="text-end">{!!x.mktCap ? toCurrency(x.mktCap / 1000000, 0, 0) : ''}</td>}
                                    {canShow('beta') && <td className="text-end">{toNum(x.beta)}</td>}
                                    {canShow('pe') && <td className="text-end"><span style={{backgroundColor: x.pe >= 10 && x.pe <= 20 ? '#adff2f' : '' }}>{toNum(x.pe)}</span></td>}
                                    {canShow('eps') && <td className={`text-end ${x.eps < 0 ? 'red':''}`}><span style={{backgroundColor: x.eps >= 25 ? '#adff2f' : '' }}>{toNum(x.eps)}</span></td>}
                                    {canShow('targetPrice') && <td className={`text-end ${x.targetPrice >= x.price ? 'blue' : 'red'}`}><span style={{ backgroundColor: getBgColor2((x.targetPrice - x.price) / x.price) }}>{toCurrency(x.targetPrice)}</span></td>}
                                    {canShow('earnDate') && <td><span style={{ color: `${new Date(x.earnDate) < new Date() ? '#aaaaaa' : ''}`, backgroundColor: getDateColor(x.earnDate) }}><DateTime date={x.earnDate} kind="absolute" fmt="yyyy-MM-dd" /></span></td>}
                                    {canShow('divDate') && <td><span style={{ color: `${new Date(x.divDate) < new Date() ? '#aaaaaa' : ''}`, backgroundColor: getDateColor(x.divDate) }}><DateTime date={x.divDate} kind="absolute" fmt="yyyy-MM-dd" /></span></td>}
                                    {canShow('divValue') && <td className="text-end">{!!x.divValue && toCurrency(x.divValue)}</td>}
                                    {canShow('divYield') && <td className={`text-end ${x.divFreq === 'M' || x.divFreq === 'W' ? 'bold' : ''}`}><span title={`DivFreq: ${x.divFreq||'-'}, DivSince: ${x.divSince||'-'}, ExpenseRatio: ${x.expenseRatio||'-'}, PayoutRatio: ${x.payoutRatio||'-'}`} style={{ backgroundColor: getBgColor(x.divYield / 100) }}>{!!x.divYield && toPercent(x.divYield / 100, 2)}</span></td>}
                                    {canShow('divFreq') && <td>{x.divFreq}</td>}
                                    {canShow('divSince') && <td>{x.divSince}</td>}
                                    {canShow('expenseRatio') && <td className="text-end"><span style={{ backgroundColor: getBgColor5(x.expenseRatio) }}>{!!x.expenseRatio && toPercent(x.expenseRatio/100, 2)}</span></td>}
                                    {canShow('payoutRatio') && <td className="text-end"><span style={{ backgroundColor: getBgColor4(x.payoutRatio / 100) }}>{!!x.payoutRatio && toPercent(x.payoutRatio/100, 2)}</span></td>}
                                    {canShow('priceToSales') && <td className="text-end">{x.priceToSales}</td>}
                                    {canShow('priceToBook') && <td className="text-end">{x.priceToBook}</td>}
                                    {canShow('evToRevenue') && <td className="text-end">{x.evToRevenue}</td>}
                                    {canShow('evToEbitda') && <td className="text-end">{x.evToEbitda}</td>}
                                    {canShow('ytdReturn') && <td className="text-end"><span style={{ backgroundColor: getBgColor3(x.ytdReturn / 100) }}><a href={`https://finance.yahoo.com/quote/${x.yahooSymbol||x.symbol}/performance/`} target="_blank" rel="noopener noreferrer">{!!x.ytdReturn && toPercent(x.ytdReturn/100, 2)}</a></span></td>}
                                    {canShow('t3mReturn') && <td className="text-end"><span style={{ backgroundColor: getBgColor3(x.t3mReturn / 100) }}>{!!x.t3mReturn && toPercent(x.t3mReturn/100, 2)}</span></td>}
                                    {/* {canShow('targetPrice') && <td className={`text-end ${x.targetPrice >= x.price ? 'blue' : 'red'}`}><div style={{ backgroundColor: getBgColor2((x.targetPrice - x.price) / x.price) }}>{toCurrency(x.targetPrice)}</div></td>}
                                    {canShow('earnDate') && <td><DateTime date={x.earnDate} kind="absolute" fmt="yyyy-MM-dd"/></td>}
                                    {canShow('divDate') && <td><DateTime date={x.divDate} kind="absolute" fmt="yyyy-MM-dd"/></td>}
                                    {canShow('divYield') && <td className="text-end"><div style={{ backgroundColor: getBgColor(x.divYield / 100) }}>{!!x.divYield && toPercent(x.divYield / 100, 2)}</div></td>} */}
                                    {canShow('category') && <td>{x.category}</td>}
                                    {canShow('sector') && <td title={x.sector} style={{maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis'}}>{x.sector}</td>}
                                    {canShow('industry') && <td title={`Established: ${x.year}, HQ: ${x.hq}, Country: ${x.country}, Sector: ${x.sector}, Industry: ${x.industry}`} style={{maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis'}}>{x.industry}</td>}
                                    {canShow('kind') && <td title={x.kind}>{x.kind}</td>}
                                    {canShow('type') && <td title={x.type}>{x.type}</td>}
                                    {canShow('year') && <td><span title={`HQ: ${x.hq||'-'}, Country: ${x.country||'-'}, Sector: ${x.sector||'-'}, Industry: ${x.industry||'-'}`}>{x.year||''}</span></td>}
                                    {canShow('hq') && <td title={x.hq} style={{maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis'}}>{x.hq}</td>}
                                    {canShow('action') && <td title={x.action} style={{ maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis' }}>{x.action}</td>}
                                    {canShow('remarks') && <td title={x.remarks} style={{ maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}><DivHtml html={x.remarks} /></td>}
                                    {canShow('yahooSymbol') && <td title={x.name}><ALink href={getSymbolUrl(x.yahooSymbol || x.symbol)} text={x.yahooSymbol} /></td>}
                                    {canShow('pPrice') && <td className="text-end bold">{x.pPrice && ('$' + toNum(x.pPrice * (x.factor || 1), 4, true))}</td>}
                                    {canShow('pChange') && <td className="text-end" style={{ color: x.pChange < 0 ? 'red' : 'blue' }}>{toCurrency(x.pChange)}</td>}
                                    {canShow('pChangePercent') && <td><div className={`${x.pChange > 0 ? 'blue' : 'red'} text-end`} style={{ backgroundColor: getBgColor(x.pChangePercent / 100) }}>{!!x.pChangePercent && toPercent(x.pChangePercent / 100, 2)}</div></td>}
                                    {canShow('pLastTraded') && <td className="text-center"><DateTime date={x.pLastTraded} kind="absolute" compact /></td>}
                                    {canShow('updatedAt') && <td style={{ maxWidth: 50 }}><DateTime date={x.updatedAt} fmt="yyyy-MM-dd" /></td>}
                                    <td className="text-end nowrap">
                                        {state.pinTrans && <LinkButton variant="outline-secondary" text="T" onClick={e => showTrans(x.symbol)} className="py-0" />}
                                        <MyPopover button={<FaEllipsisV className="ms-2 grey" />} style={{ minWidth: 200 }}>
                                            <Pin pinned={state.pinTrans} onClick={e => onToggle('pinTrans')}><LinkButton text="View transactions" onClick={e => showTrans(x.symbol)} className="ps-0 pt-0" /></Pin>
                                            <hr className="my-2" />
                                            <div><LinkButton to={`/company/add`} icon={<FaPlus />} text="Add ticker" className="ps-0" /></div>
                                            {x.company && <div><LinkButton to={`/company/${x.company._id}/edit`} icon={<FaEdit />} text="Edit ticker" className="ps-0" /></div>}
                                            <hr className="my-2" />
                                            <AddSymbolToWatchlist symbol={x.symbol} />
                                        </MyPopover>
                                    </td>
                                </tr>)}
                                <tr className="bold" style={{ backgroundColor: '#eee' }}>
                                    <td colSpan={6}>
                                        Total: {toCurrency(account1.totalValue)} &nbsp;
                                        Cash: {toCurrency(account1.cash) || '$0.00'} {Math.abs(account1.cash) > 0.01 && <>({toPercent(account1.cashPercent, 2) || '0%'})</>} &nbsp;
                                        {!!hiddenMF && <span className="text-info">[{hiddenMF} MF hidden]</span>}
                                    </td>
                                    {canShow('name') && <td className="text-end"></td>}
                                    {/* {canShow('price') && <td className="text-end"></td>}
                                    {canShow('change') && <td className="text-end"></td>}
                                    {canShow('changePercent') && <td className="text-end"></td>}
                                    {canShow('lastTraded') && <td></td>} */}
                                    {canShow('dayRange') && <td className="text-center"></td>}
                                    {canShow('volume') && <td className="text-center"></td>}
                                    {canShow('avgVolume') && <td className="text-center"></td>}
                                    {canShow('yearRange') && <td className="text-center"></td>}
                                    {canShow('position') && <td className="text-center"></td>}
                                    {canShow('upPercent') && <td className="text-center"></td>}
                                    {canShow('downPercent') && <td className="text-center"></td>}
                                    {canShow('tickerRemarks') && <td></td>}
                                    {canShow('quantity') && <td className="text-end">{toNum(account1.quantity, 5, true)}</td>}
                                    {/* {canShow('avgPrice') && <td className="text-end">${toNum(account1.avgPrice, 4, true)}</td>} */}
                                    {canShow('avgPrice') && <td className="text-end"></td>}
                                    {canShow('paid') && <td className="text-end">{toCurrency(account1.paid)}</td>}
                                    {canShow('marketValue') && <td className="text-end">{toCurrency(account1.marketValue)}</td>}
                                    {canShow('dayChange') && <td className={`text-end ${account1.dayChange < 0 ? 'red' : 'blue'}`}>{toCurrency(account1.dayChange)}</td>}
                                    {canShow('winAmount') && <td className={`text-end ${account1.winAmount < 0 ? 'red' : 'blue'}`}>{toCurrency(account1.winAmount)}</td>}
                                    {canShow('winPercent') && <td className={`text-end ${account1.winPercent < 0 ? 'red' : 'blue'}`}>{toPercent(account1.winPercent, 1)}</td>}
                                    {canShow('percentage') && <td className="text-end">{toPercent(account1.percentage, 1)}</td>}
                                    <td colSpan={20} className="text-end"><span className="text-muted">Updated:</span> <DateTime date={items[0].updatedAt} fmt="yyyy-MM-dd" /></td>
                                    {/* {canShow('divYield') && <td></td>}
                                    {canShow('category') && <td></td>}
                                    {canShow('yahooSymbol') && <td></td>}
                                    {canShow('remarks') && <td></td>}
                                    {canShow('updatedAt') && <td></td>} */}
                                    {/* <td></td> */}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>}</>}
                {state.showCharts && <HoldingsChart items={items} columns={state.columns} onClose={e => onToggle('showCharts')} />}
                {state.showTransactions && <Transactions account={account} symbol={state.symbol} quote={items.find(x => x.symbol === state.symbol)} onClose={e => onToggle('showTransactions')} />}
            </div>}
            {(!embedded || showOrders) && state.showOrders && <Orders account={account1} pQuote={pQuote} onClose={e => onToggle('showOrders')} />}
        </div >
    )
}