import React, { useState, Fragment } from 'react'
import { Table, Form, Row, Col } from 'react-bootstrap'
import { MyPopover, DateTime, LinkButton, QuoteRange, ThSort, NoData, Checkbox, ConfirmButton, Ticker, Pin } from '../widgets'
import { orderBy, get } from 'lodash'
import { trimTo, toNumber, toPercent, copyTable, toNum, toCurrency, getPositionColor, getPriceColor, getVolumeColor } from '../../libs/utilLib'
import { quotesView } from '../../libs/consts'
import { FaEllipsisH, FaCopy, FaEllipsisV, FaTrash, FaEdit } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { SparkAreaChart, SparkLineChart } from '../chart'
import { mathLib } from '../../libs/mathLib'
import { AddSymbolToWatchlist } from '../watchlist'
import { differenceInDays } from 'date-fns'

export const Quotes = ({ quotes, skip, searchText, watchlist, height, onRemoveSymbol }) => {
  const prefix = 'Quotes'
  const [state, setState] = useState({
    columns: JSON.parse(localStorage.getItem(`${prefix}_columns`)) || quotesView.columns,
    sortBy: JSON.parse(localStorage.getItem(`${prefix}_sortBy`)) || 'symbol',
    sortDir: JSON.parse(localStorage.getItem(`${prefix}_sortDir`)) || 'desc',
    pinRemarks: JSON.parse(localStorage.getItem(`${prefix}_pinRemarks`)),
    pinInfo: JSON.parse(localStorage.getItem(`${prefix}_pinInfo`)),
    pinChart: JSON.parse(localStorage.getItem(`${prefix}_pinChart`) || true),
    pinEdit: JSON.parse(localStorage.getItem(`${prefix}_pinEdit`) || true),
    hideEmpty: JSON.parse(localStorage.getItem(`${prefix}_hideEmpty`) || true),
    working: false
  })

  const onSort = (sortBy) => {
    const sortDir = sortBy === state.sortBy ? (state.sortDir === 'desc' ? 'asc' : 'desc') : 'asc'
    localStorage.setItem(`${prefix}_sortBy`, JSON.stringify(sortBy))
    localStorage.setItem(`${prefix}_sortDir`, JSON.stringify(sortDir))
    setState({ ...state, sortBy, sortDir })
  }

  const onToggle = (name) => {
    localStorage.setItem(`${prefix}_${name}`, JSON.stringify(!state[name]))
    setState({ ...state, [name]: !state[name] })
  }

  const onToggleColumn = (col) => {
    let column = state.columns.find(x => x.id === col.id)
    if (column) column.visible = !column.visible
    localStorage.setItem(`${prefix}_columns`, JSON.stringify(state.columns))
    setState({ ...state, columns: state.columns })
  }

  const onReset = () => {
    localStorage.removeItem(`${prefix}_columns`)
    setState({ ...state, columns: quotesView.columns })
  }

  const onStop = (e) => {
    e.stopPropagation()
  }

  const onCopy = () => {
    let result = copyTable(items, state.columns)
    if (result) toast(`Copied data in TSV to clipboard.`)
    else toast.error(`Failed to copy data in TSV to clipboard.`)
  }

  const onCopySymbols = () => {
    let str = items.map(x => x.symbol).join('+')
    if (navigator.clipboard.writeText(str)) toast(`Copied symbols to clipboard.`)
    else toast.error(`Failed to copy data to clipboard.`)
  }

  // const getTickerRemarks = (company) => {
  //   return null
  //   // const { tickerRemarks = [] } = gState.loginUser
  //   // let tr = tickerRemarks.find(x => x.symbol === company.symbol)
  //   // return tr ? tr.remark : null
  // }

  const canShow = (id) => {
    let isSpecial = 'yearRange,dailySparkline,yearlySparkline,macd,rsi'.includes(id)
    if (id === 'dayRange' && state.hideEmpty) return items.find(x => x.dayLow)
    if (!isSpecial && state.hideEmpty && !items.find(x => x[id])) return false
    return state.columns.find(x => x.id === id)?.visible
  }

  const goYahoo = (symbol, id) => {
    let url = `https://finance.yahoo.com/quote/${symbol}/${id}`
    window.open(url)
  }

  const getBgColor = (pct, options = {}) => {
    const {pos1 = 5, pos2 = 10, pos3 = 15, neg1 = -5, neg2 = -10, neg3 = -15} = options
    if (pct > pos3) return '#80ff00'
    else if (pct > pos2) return '#00ffff'
    else if (pct > pos1) return '#ffff00'
    else if (pct < neg3) return '#ff4500'
    else if (pct < neg2) return '#f08080'
    else if (pct < neg1) return '#ffa07a'
    return ''
  }

  const getBgColor4 = (pct) => {
    if (pct > 150) return '#ff4500'
    else if (pct > 100) return '#f08080'
    else if (pct > 50) return '#ffff00'
    return ''
  }

  const getDateColor = (dt) => {
    if (!dt) return
    if (typeof(dt) == 'string') dt = new Date(dt)
    let days = differenceInDays(dt, new Date())
    if (days < 0) return
    if (days <= 7) return '#ffff00'
    else if (days <= 14) return '#00ffff'
    else if (days <= 30) return '#80ff00'
    return ''
  }

  const getDiffColor = (order) => {
    let { diffPercent, buyPrice, dayLow } = order
    if (!buyPrice) return ''
    if (buyPrice > dayLow) return 'red'
    if (diffPercent <= 0) return 'red'
    if (diffPercent < 0.01) return '#ffff00'
    else if (diffPercent < 0.05) return '#00ffff'
    else if (diffPercent < 0.1) return '#80ff00'
  }

  const items = orderBy(quotes, item => get(item, state.sortBy, ''), [state.sortDir])
  // const getSymbolUrl = (symbol) => `https://finance.yahoo.com/chart/${symbol}`
  const getNameUrl = (symbol) => `https://finance.yahoo.com/quote/${symbol}/profile`
  // console.log(items)
  return (
    <div className="clear-both" style={{ maxWdith: "100%", maxHeight: height, overflow: 'auto' }}>
      <Table hover size="sm" className="nowrap f-sm">
        <thead>
          <tr>
            <th>#</th>
            {state.columns.filter(x => canShow(x.id)).map((x, i) => <ThSort key={i} id={x.id} text={x.name} sortBy={state.sortBy} sortDir={state.sortDir} onSort={e => onSort(x.id)} className={x.className} />)}
            <th className="text-end hide-on-print">
              <MyPopover title="" button={<FaEllipsisH className="grey c-pointer" />} className="ms-2 mt-n1">
                <div><LinkButton onClick={e => onCopy()} icon={<FaCopy />} text="Copy table in TSV format" title="Copy the table with all visible columns into the clipboard" className="ps-0" /></div>
                <div><LinkButton onClick={e => onCopySymbols()} icon={<FaCopy />} text="Copy symbols only" title="Copy symbols into the clipboard" className="ps-0" /></div>
                <hr className="my-1" />
                <div><Checkbox label="Hide empty columns" checked={state.hideEmpty} onCheck={e => onToggle('hideEmpty')} /></div>
                <hr className="my-1" />
                <div className="float-end"><LinkButton text="Reset to default" onClick={onReset} className="p-0" /></div>
                <div className="bold">Show columns</div>
                <Row className="clear-both mt-2" onClick={onStop}>
                  {state.columns.map((x, i) => <Col key={i} nm={6} onClick={e => onToggleColumn(x)}>
                    <div style={{ minWidth: 100 }}><Form.Check type="checkbox" custom="true" checked={!!x.visible} label={x.name} readOnly disabled={!!x.cantHide} className="me-n2" /></div>
                  </Col>)}
                </Row>
              </MyPopover>
            </th>
          </tr>
        </thead>
        <tbody>
          {items?.length ? items.map((x, i) => <Fragment key={i}><tr>
            <td><LinkButton text={i + 1 + skip} to={`/chart/${x.symbol}`} target="_blank" className="p-0" /></td>
            {canShow('symbol') && <td>
              <Ticker symbol={x.symbol} yahooSymbol={x.yahooSymbol}/>
              {/* {gState.loginUser && <div className="float-end">
                <MyPopover button={gState.isFavor(x.symbol, 'watch') ? <FaHeart className="blueviolet" title="Watching" /> : gState.isFavor(x.symbol, 'buy') ? <FaThumbsUp className="blueviolet" title="Watch for buy" /> : gState.isFavor(x.symbol, 'sell') ? <FaThumbsDown className="blueviolet" title="Watch for sell" /> : <FaRegHeart title="Favorite?" />}>
                  <div><LinkButton icon={gState.isFavor(x.symbol, 'watch') ? <FaHeart className="blueviolet" /> : <FaRegHeart />} text="Watching" onClick={e => gState.onFavor(x.symbol, 'watch')} /></div>
                  <div><LinkButton icon={gState.isFavor(x.symbol, 'buy') ? <FaThumbsUp className="blueviolet" /> : <FaRegThumbsUp />} text="Watch for buy" onClick={e => gState.onFavor(x.symbol, 'buy')} /></div>
                  <div><LinkButton icon={gState.isFavor(x.symbol, 'sell') ? <FaThumbsDown className="blueviolet" /> : <FaRegThumbsDown />} text="Watch for sell" onClick={e => gState.onFavor(x.symbol, 'sell')} /></div>
                </MyPopover>
              </div>}
              <a href={getSymbolUrl(x.yahooSymbol || x.symbol)} target="_blank" rel="noopener noreferrer" className="me-3"><span dangerouslySetInnerHTML={{ __html: trimTo(x.symbol, 15, searchText) }}></span></a> */}
            </td>}
            {canShow('name') && <td title={x.name} style={{ maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {x.tags && <div className="float-end f-xs">{x.tags?.map((y, j) => <span key={j} className="px-1 grey me-1" style={{ border: '1px solid #ccc', borderRadius: 5, backgroundColor: y.startsWith('DJ') ? 'azure' : y.startsWith('NQ') ? 'seashell' : y.startsWith('DK') ? 'orange' : y.startsWith('DA') ? 'pink' : y.startsWith('DV') ? 'cyan' : '' }}>{y}</span>)}</div>}
              {/* {!x.symbol?.startsWith('^') && <img src={companyImgUrl(x)} alt="" style={{ width: 25 }} />}  */}
              <a href={getNameUrl(x.yahooSymbol || x.symbol)} target="_blank" rel="noopener noreferrer"><span dangerouslySetInnerHTML={{ __html: trimTo(x.name, 80, searchText) }} title={x.name}></span></a>
            </td>}
            {canShow('price') && <td className="text-end bold" title={`Pos: ${toPercent(x.position, 2) || '0%'} Up: ${toPercent(x.upPercent, 2) || '0%'} Down: ${toPercent(x.downPercent, 2)}`}><span style={{backgroundColor: getPriceColor(x.position)}}>${toNum(x.price * (x.factor || 1), 4, true)}</span></td>}
            {/* {canShow('price') && <td className="text-end bold" title={toPercent(x.position, 2)}><span style={{backgroundColor: x.position < 0.001 ? 'red' : x.position > 0.999 ? 'green' : ''}}>${toNum(x.price * (x.factor || 1), 4, true)}</span></td>} */}
            {/* {canShow('price') && <td className="text-end bold">{toCurrency(x.price)}</td>} */}
            {canShow('change') && <td><div className={`${x.change > 0 ? 'blue' : 'red'} text-end`}>{toCurrency(x.change)}</div></td>}
            {canShow('changePercent') && <td><div className={`${x.change > 0 ? 'blue' : 'red'} text-end`} style={{ backgroundColor: getBgColor(Math.abs(x.changePercent)) }}>{!!x.changePercent && toPercent(x.changePercent / 100, 2)}</div></td>}
            {canShow('lastTraded') && <td className="text-center"><span style={{backgroundColor: differenceInDays(x.lastTraded, new Date()) < -5 ? 'red':''}}><DateTime date={x.lastTraded} kind="absolute" compact /></span></td>}
            {canShow('bidSize') && <td className="text-end">{x.bidSize}</td>}
            {canShow('bidPrice') && <td className="text-end">{x.bidPrice}&nbsp;</td>}
            {canShow('askPrice') && <td className="text-start">&nbsp;{x.askPrice}</td>}
            {canShow('askSize') && <td className="text-start">{x.askSize}</td>}
            {canShow('prevClose') && <td className="text-end">{toNumber(x.prevClose)}</td>}
            {canShow('open') && <td className="text-end">{toNumber(x.open)}</td>}
            {canShow('dayRange') && <td className="text-center" style={{ minWidth: 110 }}><QuoteRange low={x.dayLow} high={x.dayHigh} close={x.price} open={x.open} /></td>}
            {canShow('dayLow') && <td className="text-end">{toNumber(x.dayLow)}</td>}
            {canShow('dayHigh') && <td className="text-end">{toNumber(x.dayHigh)}</td>}
            {canShow('volume') && <td className="text-end" title={`3M avg: ${toNumber((x.avgVolume||0)/1000, 1, 1)}, 10D avg: ${toNumber((x.avgVolume10D||0)/1000, 1, 1)}`}><div style={{backgroundColor: getVolumeColor(x.volume, x.avgVolume)}}>{x.volume ? toNumber(x.volume / 1000, 1, 1) : ''}</div></td>}
            {canShow('avgVolume') && <td className="text-end">{x.avgVolume ? toNumber(x.avgVolume / 1000, 1, 1) : ''}</td>}
            {canShow('yearRange') && <td className="text-center" style={{ minWidth: 100 }}><QuoteRange low={Math.min(x.yearLow, x.dayLow||x.yearLow)} high={Math.max(x.yearHigh, x.dayHigh)} close={x.price} open={x.open} kind="year" /></td>}
            {canShow('yearLow') && <td className="text-end">{toNumber(x.rangeLow)}</td>}
            {canShow('yearHigh') && <td className="text-end">{toNumber(x.rangeHigh)}</td>}
            {canShow('position') && <td className="text-end"><span style={{ backgroundColor: getPositionColor(x.position) }}>{toPercent(x.position, 2)}</span></td>}
            {canShow('upPercent') && <td className="text-end">{toPercent(x.upPercent, 2)}</td>}
            {canShow('downPercent') && <td className="text-end">{toPercent(x.downPercent, 2)}</td>}
            {canShow('mktCap') && <td className="text-end">{!!x.mktCap ? toCurrency(x.mktCap / 1000000, 0, 0) : ''}</td>}
            {canShow('beta') && <td className="text-end">{toNum(x.beta)}</td>}
            {canShow('pe') && <td className="text-end"><span style={{backgroundColor: x.pe >= 10 && x.pe <= 20 ? '#adff2f' : '' }}>{toNum(x.pe)}</span></td>}
            {canShow('eps') && <td className={`text-end ${x.eps < 0 ? 'red':''}`}><span style={{backgroundColor: x.eps >= 25 ? '#adff2f' : '' }}>{toNum(x.eps)}</span></td>}
            {canShow('targetPrice') && <td className={`text-end ${x.targetPrice >= x.price ? 'blue' : 'red'}`}><span style={{ backgroundColor: getBgColor(Math.abs((x.targetPrice - x.price) / x.price), {pos1: 20, pos2: 40, pos3: 80}) }}>{toCurrency(x.targetPrice)}</span></td>}
            {canShow('earnDate') && <td><span style={{ color: `${new Date(x.earnDate) < new Date() ? '#aaaaaa': ''}`, backgroundColor: getDateColor(x.earnDate)}}><DateTime date={x.earnDate} kind="absolute" fmt="yyyy-MM-dd" /></span></td>}
            {canShow('divDate') && <td><span style={{ color: `${new Date(x.divDate) < new Date() ? '#aaaaaa': ''}`, backgroundColor: getDateColor(x.divDate)}}><DateTime date={x.divDate} kind="absolute" fmt="yyyy-MM-dd" /></span></td>}
            {canShow('divValue') && <td className="text-end">{!!x.divValue && toCurrency(x.divValue)}</td>}
            {canShow('divYield') && <td className={`text-end ${x.divFreq === 'M' || x.divFreq === 'W' ? 'bold' : ''}`}><span title={`DivFreq: ${x.divFreq||'-'}, DivSince: ${x.divSince||'-'}, ExpenseRatio: ${x.expenseRatio||'-'}, PayoutRatio: ${x.payoutRatio||'-'}`} style={{ backgroundColor: getBgColor(x.divYield / 100) }}>{!!x.divYield && toPercent(x.divYield / 100, 2)}</span></td>}
            {canShow('divFreq') && <td>{x.divFreq}</td>}
            {canShow('divSince') && <td>{x.divSince}</td>}
            {canShow('expenseRatio') && <td className="text-end"><span style={{ backgroundColor: getBgColor(x.expenseRatio, {pos1: 0.5, pos2: 0.75, pos3: 1}) }}>{!!x.expenseRatio && toPercent(x.expenseRatio/100, 2)}</span></td>}
            {canShow('payoutRatio') && <td className="text-end"><span style={{ backgroundColor: getBgColor4(x.payoutRatio) }}>{!!x.payoutRatio && toPercent(x.payoutRatio/100, 2)}</span></td>}
            {canShow('priceToSales') && <td className="text-end">{x.priceToSales}</td>}
            {canShow('priceToBook') && <td className="text-end">{x.priceToBook}</td>}
            {canShow('evToRevenue') && <td className="text-end">{x.evToRevenue}</td>}
            {canShow('evToEbitda') && <td className="text-end">{x.evToEbitda}</td>}
            {canShow('ytdReturn') && <td className="text-end"><span style={{ backgroundColor: getBgColor(x.ytdReturn) }}><a href={`https://finance.yahoo.com/quote/${x.yahooSymbol||x.symbol}/performance/`} target="_blank" rel="noopener noreferrer">{!!x.ytdReturn && toPercent(x.ytdReturn/100, 2)}</a></span></td>}
            {canShow('t3mReturn') && <td className="text-end"><span style={{ backgroundColor: getBgColor(x.t3mReturn) }}>{!!x.t3mReturn && toPercent(x.t3mReturn/100, 2)}</span></td>}
            {/* {canShow('pe') && <td className="text-end">{toNumber(x.pe)}</td>}
            {canShow('divValue') && <td className="text-end">{!!x.divValue && toNumber(x.divValue)}</td>}
            {canShow('divYield') && <td className="text-end"><div style={{ backgroundColor: getBgColor(x.divYield / 100) }}>{!!x.divYield && toPercent(x.divYield / 100, 2)}</div></td>}
            {canShow('divDate') && <td><DateTime date={x.divDate} kind="absolute" fmt="yyyy-MM-dd" /></td>} */}
            {canShow('exchange') && <td>{x.exchange}</td>}
            {/* {canShow('remark') && <td style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>{getTickerRemarks(x)}</td>} */}
            {canShow('yahooSymbol') && <td>{x.yahooSymbol}</td>}
            {canShow('dailySparkline') && <td height="25" width="100"><SparkAreaChart dates={x.dailyQuotes.dates} values={x.dailyQuotes.prices} width={100} height={25} /></td>}
            {canShow('yearlySparkline') && <td height="25" width="100"><SparkAreaChart dates={x.yearlyQuotes.dates} values={x.yearlyQuotes.prices} width={100} height={25} /></td>}
            {canShow('macd') && <td height="25" width="100"><SparkAreaChart dates={x.yearlyQuotes.dates} values={mathLib.MACD({ arr: x.yearlyQuotes.prices })[2]} width={100} height={25} /></td>}
            {canShow('rsi') && <td height="25" width="100"><SparkLineChart dates={x.yearlyQuotes.dates} values={mathLib.RSI({ arr: x.yearlyQuotes.prices })} width={100} height={25} lines={[20, 80]} /></td>}
            {canShow('sector') && <td title={x.sector} style={{maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis'}}>{x.sector}</td>}
            {canShow('industry') && <td title={`Established: ${x.year}, HQ: ${x.hq}, Country: ${x.country}, Sector: ${x.sector}, Industry: ${x.industry}`} style={{maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis'}}>{x.industry}</td>}
            {canShow('kind') && <td title={x.kind}>{x.kind}</td>}
            {canShow('type') && <td title={x.type}>{x.type}</td>}
            {canShow('year') && <td><span title={`HQ: ${x.hq||'-'}, Country: ${x.country||'-'}, Sector: ${x.sector||'-'}, Industry: ${x.industry||'-'}`}>{x.year||''}</span></td>}
            {canShow('country') && <td title={x.country} style={{maxWidth: 80, overflow: 'hidden', textOverflow: 'ellipsis'}}>{x.country}</td>}
            {canShow('hq') && <td title={x.hq} style={{maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis'}}>{x.hq}</td>}
            {canShow('buyPrice') && <td className="text-end"><span style={{ backgroundColor: getDiffColor(x) }}>{x.buyPrice && toCurrency(x.buyPrice)}</span></td>}
            {canShow('diffAmount') && <td className="text-end">{toCurrency(x.diffAmount)}</td>}
            {canShow('diffPercent') && <td className="text-end"><span style={{ backgroundColor: getDiffColor(x) }}>{toPercent(x.diffPercent, 1)}</span></td>}
            {canShow('weight') && <td className="text-end"><span style={{ backgroundColor: getBgColor(x.weight) }}>{toPercent((x.weight||0)/100, 2)}</span></td>}
            {canShow('action') && <td title={x.action} style={{ maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis' }}>{x.action}</td>}
            {canShow('remarks') && <td title={x.remarks} style={{ maxWidth: 120, overflow: 'hidden', textOverflow: 'ellipsis' }}>{x.remarks}</td>}
            {canShow('pPrice') && <td className="text-end bold">{x.pPrice && ('$' + toNum(x.pPrice * (x.factor || 1), 4, true))}</td>}
            {canShow('pChange') && <td className="text-end" style={{ color: x.pChange < 0 ? 'red' : 'blue' }}>{toCurrency(x.pChange)}</td>}
            {canShow('pChangePercent') && <td><div className={`${x.pChange > 0 ? 'blue' : 'red'} text-end`} style={{ backgroundColor: getBgColor(Math.abs(x.pChangePercent)) }}>{!!x.pChangePercent && toPercent(x.pChangePercent / 100, 2)}</div></td>}
            {canShow('pLastTraded') && <td className="text-center"><DateTime date={x.pLastTraded} kind="absolute" compact /></td>}
            {canShow('updatedAt') && <td style={{ maxWidth: 50 }}><DateTime date={x.updatedAt} fmt="yyyy-MM-dd" /></td>}
            {/* {canShow('remarks') && <td title={x.remarks} style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis' }}>{x.remarks}</td>} */}
            <td width="10" className="text-end nowrap">
            {state.pinEdit && <LinkButton to={`/company/${x._id}/edit`} icon={<FaEdit />} className="py-0 me-1 grey c-pointer" />}
            <MyPopover button={<FaEllipsisV className="grey c-pointer" />}>
                <Pin pinned={state.pinEdit} onClick={e => onToggle('pinEdit')}><LinkButton to={`/company/${x._id}/edit`} icon={<FaEdit />} text="Edit ticker info"  className="ps-0 pt-0" /></Pin>
                <hr className="my-2" />
                <div><LinkButton text="Yahoo summary" onClick={e => goYahoo(x.symbol, 'quote')} className="ps-0" /></div>
                <div><LinkButton text="FMP summary" onClick={e => window.open(`https://site.financialmodelingprep.com/financial-summary/${x.symbol}`)} className="ps-0" /></div>
                {watchlist?._id !== '_markets_' && <>
                  <hr className="my-2" />
                  <div><ConfirmButton variant="link" icon={<FaTrash className="red" />} text={`Remove ${x.symbol}`} onClick={e => onRemoveSymbol(x.symbol)} className="ps-0" /></div>
                  <AddSymbolToWatchlist symbol={x.symbol} fromWatchlist={watchlist} onRemoveSymbol={onRemoveSymbol} />
                </>}
              </MyPopover>
            </td>
          </tr>
          </Fragment>) : <tr><td colSpan="10"><NoData /></td></tr>}
        </tbody>
      </Table>
    </div>
  )
}
